<template>
    <div class="vx-row">
      <div
          class="vx-col w-full sm:w-full md:1/2 lg:w-1/2 xl:w-1/3 mb-base"
      >
        <PipelineCards/>
      </div>
      <div
          class="vx-col w-full sm:w-full md:1/2 lg:w-1/2 xl:w-1/3 mb-base"
      >
        <TodaysAdviceAppointments/>
      </div>
    </div>
</template>

<script>
import PipelineCards from './Cards/Pipeline'
import TodaysAdviceAppointments from './Cards/TodaysAdviceAppointments'

export default {
  name: 'Home',
  components: {
    PipelineCards,
    TodaysAdviceAppointments,
  },
  computed: {},
  data() {
    return {}
  },
  mounted() {
  },
}
</script>
