<template>
    <vx-card
        :title="title"
        :subtitle="subTitle"
        :showOverflow="true"
        :edit-action="true"
        @edit="show.edit = !show.edit"
        @search="show.search = !show.search"
        title-color="#fff"
        subtitle-color="#fff"
        content-color="#fff"
        :search="true"
        card-background="rgba(var(--vs-success),0.65)"
    >
        <div v-if="show.search" class="w-full mb-5">
            <div id="cal">
                <Calendar
                    is-expanded
                    is-inline
                    mode="single"
                    v-model="date"
                    :attributes="attrs"
                    @update:to-page="getMonth"
                    @dayfocusin="getDate"
                ></Calendar>
            </div>
        </div>

        <div class="mb-5" v-if="appointments && appointments.length">
            <div v-for="(app, index) in appointments" :key="index" class="flex">
                <div class="vx-col w-1/4">
                    <span class="font-bold"
                        >{{ formatTime(app.date_time) || '--:--' }}
                    </span>
                </div>
                <div class="vx-col w-full" v-if="app.user && app.user.details">
                    <span
                        class="link"
                        @click="
                            $router.push({
                                name: 'user',
                                params: { id: app.user.details.user_id },
                            })
                        "
                    >
                        {{ app.user.details.full_name }}
                    </span>
                </div>
                <div v-else class="vx-col w-full">Available</div>
                <div v-show="app && show.edit" class="vx-col w-1/4">
                    <div class="flex flex-row-reverse">
                        <vs-button
                            type="border"
                            size="small"
                            color="secondary"
                            v-on:click="deleteAppointmentConfirm(app)"
                            icon-pack="feather"
                            icon="icon-minus"
                        ></vs-button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="show.search || show.edit" class="flex flex-row-reverse">
            <vs-button
                color="rgba(var(--vs-success),0.65)"
                v-show="show.edit"
                @click="showCreateAppointmentModal"
                class="mr-3"
                >Add</vs-button
            >
        </div>

        <vs-popup
            title="Create Appointment"
            :active.sync="show.createAppointmentModal"
        >
            <div class="vs-row">
                <div class="vs-col w-full">
                    <vs-input
                        v-model="newAppointment.date_time"
                        label="Date & Time"
                        type="datetime-local"
                        class="mb-3 w-full"
                    ></vs-input>
                </div>
            </div>

            <vs-divider class="mt-3" />

            <vs-button
                v-on:click="createAppointment"
                color="secondary"
                class="mr-3"
                :disabled="!validatedCreate"
                >Create</vs-button
            >
            <vs-button
                v-on:click="show.createAppointmentModal = false"
                type="flat"
                >Cancel</vs-button
            >
        </vs-popup>
    </vx-card>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import { textFormat } from '@/mixins/TextFormat'
import { http } from '@/services'

export default {
    name: 'TodaysAdviceAppointments',
    mixins: [textFormat],
    components: {
        Calendar,
    },
    watch: {
        date(value) {
            this.$store.dispatch('todaysAppointmentsFetch', this.date)
        },
    },
    computed: {
        appointments() {
            if (this.$store.getters.todaysAdviceAppointments) {
                var data = [...this.$store.getters.todaysAdviceAppointments]
                return data.sort(
                    (a, b) => new Date(a.date_time) - new Date(b.date_time)
                )
            }
            return null
        },
        title() {
            if (this.appointments && this.appointments.length && this.date)
                return this.formatDateLong(this.date)
            return this.formatDateLong(new Date())
        },
        subTitle() {
            if (this.appointments && this.appointments.length) {
                var booked = this.appointments.filter(
                    (appointment) => appointment.user
                ).length
                if (booked > 1) return `${booked} Appointments`
                else return `${booked} Appointment`
            } else {
                return 'No appointments'
            }
        },
        validatedCreate() {
            return !!this.newAppointment.date_time
        },
        datesApp() {
            var today = new Date()
            return this.month.map((date) => {
                return {
                    key: date.date_time,
                    dot:
                        date.user && date.user.details
                            ? new Date(date.date_time) < today
                                ? 'blue'
                                : 'pink'
                            : 'green',
                    dates: new Date(date.date_time).toDateString(),
                    popover: {
                        label:
                            date.user && date.user.details
                                ? this.formatTime(date.date_time) +
                                  ' ' +
                                  date.user.details.full_name
                                : this.formatTime(date.date_time) +
                                  ' Available',
                    },
                }
            })
        },
        attrs() {
            var dates = this.datesApp
            dates.push({
                key: 'Today',
                highlight: true,
                dates: new Date(),
            })
            return dates
        },
    },
    data() {
        return {
            date: null,
            month: [],
            show: {
                search: true,
                edit: false,
                createAppointmentModal: false,
            },
            selected: null,
            newAppointment: {
                date_time: null,
            },
        }
    },
    methods: {
        getMonth(value) {
            http.get('months_advice_appointments', { params: value })
                .then((response) => {
                    this.month = response.data
                    this.month.sort((a, b) => {
                        return new Date(a.date_time) - new Date(b.date_time)
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getDate(value) {
            this.date = value.date
        },
        deleteAppointmentConfirm(app) {
            this.selected = app.id
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Appointment`,
                text: app.user
                    ? `Delete appointment with ${app.user.details.full_name}`
                    : 'Delete blank appointment',
                accept: this.deleteAppointment,
                acceptText: 'Delete',
            })
        },
        deleteAppointment() {
            http.delete('advice_appointment', { params: { id: this.selected } })
                .then((response) => {
                    this.selected = null
                    this.$store.dispatch('todaysAppointmentsFetch', this.date)
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Appointment deleted',
                        color: 'success',
                        iconPack: 'feather',
                        position: 'top-right',
                        icon: 'icon-trash',
                    })
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        },
        showCreateAppointmentModal() {
            this.show.createAppointmentModal = true
            this.newAppointment.date_time = null
        },
        createAppointment() {
            http.post('advice_appointment', this.newAppointment)
                .then((response) => {
                    this.$store.dispatch('todaysAppointmentsFetch', this.date)
                    this.show.createAppointmentModal = false
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Appointment Created',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.show.createAppointmentModal = false
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                    })
                })
        },
    },
}
</script>

<style lang="scss">
#cal {
    .vc-title {
        color: #fff !important;
    }
    .vc-title-wrapper {
        color: #fff !important;
    }
    .vc-bg-white {
        background-color: transparent !important;
        color: white !important;
    }
    .vc-border,
    .vc-border-2,
    .vc-border-3 {
        border: none !important;
    }
    .vc-text-gray-900 {
        color: white !important;
    }
    .vc-text-gray-800 {
        color: white !important;
    }
    .vc-text-gray-700 {
        color: white !important;
    }
    .vc-text-gray-600 {
        color: white !important;
    }
    .vc-text-gray-500 {
        color: white !important;
    }
    .vc-text-gray-400 {
        color: white !important;
    }
}
</style>
