<template>
  <div class="vx-row">
    <div v-if="willsToCheck" class="vx-col w-1/2 ">
      <statistics-card-line
          hideChart
          class="mb-base"
          icon="EyeIcon"
          icon-right
          :statistic="willsToCheck.total"
          :sub-title="willsToCheck.oldest ? 'Oldest ' + formatDate(willsToCheck.oldest) : ''"
          statisticTitle="Checking"
          :color="timeColour(willsToCheck.oldest, 'check')"
          to="wills/pipeline/to_check"
      />
    </div>
    <div v-if="willsToContact" class="vx-col w-1/2">
      <statistics-card-line
          hideChart
          class="mb-base"
          icon="PhoneCallIcon"
          icon-right
          :statistic="willsToContact.total"
          statisticTitle="To Contact"
          :sub-title="willsToCheck.oldest ? 'Oldest ' +  formatDate(willsToCheck.oldest) : ''"
          :color="timeColour(willsToContact.oldest, 'contact')"
          to="wills/pipeline/to_contact"
      />
    </div>
    <div v-if="willsToApprove" class="vx-col w-1/2">
      <statistics-card-line
          hideChart
          class="mb-base"
          icon="CheckCircleIcon"
          icon-right
          :statistic="willsToApprove.total"
          statisticTitle="To Approve"
          :sub-title="willsToApprove.oldest ? 'Oldest ' +  formatDate(willsToApprove.oldest) : ''"
          :color="timeColour(willsToApprove.oldest, 'approve')"
          to="wills/pipeline/to_approve"
      />
    </div>
    <div v-if="willsToPrint" class="vx-col w-1/2">
      <statistics-card-line
          hideChart
          class="mb-base"
          icon="PrinterIcon"
          icon-right
          :statistic="willsToPrint.total"
          statisticTitle="Printing"
          :sub-title="willsToPrint.oldest ? 'Oldest ' +  formatDate(willsToPrint.oldest) : ''"
          :color="timeColour(willsToPrint.oldest, 'print')"
          to="wills/pipeline/to_print"
      />
    </div>
    <div v-if="adviceToContact" class="vx-col w-1/2">
      <statistics-card-line
          hideChart
          class="mb-base"
          icon="MessageSquareIcon"
          icon-right
          :statistic="adviceToContact.total"
          :sub-title="adviceToContact.oldest ? 'Oldest ' + formatDate(adviceToContact.oldest) : ''"
          statisticTitle="Advice Requests"
          color="success"
          to="advice/1"
      />
    </div>
  </div>
</template>

<script>
import {tableHelpers} from '@/mixins/tableHelpers'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import {textFormat} from "@/mixins/TextFormat";

export default {
  name: 'PipelineCards',
  mixins: [tableHelpers, textFormat],
  components: {
    StatisticsCardLine,
  },
  computed: {
    willsToApprove() {
      return this.$store.getters.willsToApprove
    },
    willsToCheck() {
      return this.$store.getters.willsToCheck
    },
    willsToContact() {
      return this.$store.getters.willsToContact
    },
    willsToPrint() {
      return this.$store.getters.willsToPrint
    },
    adviceToContact() {
      return this.$store.getters.adviceToContact
    },
    emailsFromUsers() {
      return this.$store.getters.emailsFromUsers
    },
  },
  methods: {},
}
</script>

<style></style>
